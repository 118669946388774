export type RefModule =
  | 'holidays'
  | 'businesstypes'
  | 'statusreasonstruct'
  | 'mcastatus'
  | 'external_document_statuses'
  | 'users'
  | 'ownertitle'
  | 'menuconsts'
  | 'industry'
  | 'doccategory'
  | 'businessattributes'
  | 'zipcodes'
  | 'industryclassifications'
  | 'transsubtype'
  | 'qanda'
  | 'mcawftaskstatuses'
  | 'mcawftasks'
  | 'questions'
  | 'transaction'
  | 'bankruptcy_allowed_values';

export const httpRefModule = (module: RefModule) => `mca/reftab/${module}`;
export const httpRefModuleRecord = (module: string, id: string | number) => `mca/reftab/${module}/${id}`;
export const httpRefUserRegister = (id: number) => `user/${id}/reqregister`;
export const httpRefUserFields = (id: number) => `mca/reftab/user/fields/${id}`;
export const httpRefUsers = () => 'user';
export const httpRefDownload = (component: string, id: number) => `mca/download/${component}/${id}`;
export const httpHoliday = (id: number) => `mca/reftab/holidays/${id}`;

export const httpExternalResourceStatuses = () => `mca/reftab/external_document_statuses`;
export const httpDocuSignStatuses = () => `external_resources/documents`;
