import { Injectable, inject } from '@angular/core';
import { ApiService } from '@mca/shared/util';
import { httpHoliday, httpRefModule } from '../infrastructure/references-http-points';
import { dateAsYYYYMMDD, localDateAtUtc } from '@mca/shared/util';
import { RxState } from '@rx-angular/state';
import { catchError, endWith, filter, map, of } from 'rxjs';
import { isWeekend, isSameDay, toDate, differenceInBusinessDays, compareAsc } from 'date-fns';
import { selectSlice } from '@rx-angular/state/selections';

export interface Holiday {
  id: number;
  date: string;
  dateObject: Date;
  country: 'US';
}

interface State {
  holidays: Holiday[];
  loading: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HolidaysService extends RxState<State> {
  private apiService = inject(ApiService);

  get holidaysDays(): string[] {
    return this.get('holidays').map(data => data.date);
  }
  get holidaysDates(): Date[] {
    return this.get('holidays').map(data => data.dateObject);
  }
  get loadedHolidays$() {
    return this.select().pipe(
      selectSlice(['holidays', 'loading']),
      filter(({ loading }) => !loading),
      map(({ holidays }) => holidays),
    );
  }

  fetchHolidays() {
    if (!this.get('holidays')) {
      this.set({ loading: true });
    }
    this.connect(
      this.getHolidays().pipe(
        map(items => ({
          holidays: items
            .map((item: Partial<Holiday>) => {
              const utcDate = localDateAtUtc(new Date((item as Holiday).date));
              return { ...item, dateObject: utcDate, date: dateAsYYYYMMDD(utcDate) } as Holiday;
            })
            .sort((a: Holiday, b: Holiday) => compareAsc(a.dateObject, b.dateObject)),
        })),
        catchError(() => of({ holidays: [] })),
        endWith({ loading: false }),
      ),
    );
  }

  isHoliday(date: Date) {
    return this.holidaysDates.some(v => isSameDay(v, date));
  }

  isBusinessDay(date: Date) {
    return !isWeekend(date) && !this.isHoliday(date);
  }

  forwardToBusinessDay(date: Date) {
    return this.isBusinessDay(date) ? date : this.addBusinessDays(date, 1);
  }

  addBusinessDays(date: Date, amount: number) {
    const sign = Math.sign(amount);
    const result = toDate(date);
    while (amount !== 0) {
      result.setDate(result.getDate() + sign);
      if (this.isBusinessDay(result)) {
        amount -= sign;
      }
    }
    return result;
  }

  businessDiff(left: Date, right: Date) {
    const [from, to] = [left, right].sort(compareAsc);
    const holidaysDiffInBetween = to > from ? this.holidaysDates.filter(v => v > from && v < to && !isWeekend(v)).length : 0;
    const fromIsHoliday = this.isHoliday(from) && !isWeekend(from);
    const toIsHoliday = this.isHoliday(to) && !isWeekend(to);
    // eslint-disable-next-line no-bitwise
    const holidaysDiffOnEdges = +fromIsHoliday & +toIsHoliday;
    return differenceInBusinessDays(to, from) - holidaysDiffInBetween - holidaysDiffOnEdges;
  }

  /**
   * CRUD methods
   */

  private getHolidays() {
    return this.apiService.get(httpRefModule('holidays'));
  }

  createHolidays(body: any) {
    return this.apiService.put(httpRefModule('holidays'), body);
  }

  updateHolidays(id: number, body: any) {
    return this.apiService.post(httpHoliday(id), body);
  }

  deleteHolidays(id: number) {
    return this.apiService.delete(httpHoliday(id));
  }
}
